<template>
  <div class="oper">
    <el-row>
      <el-page-header
        @back="goBack"
        content="运费详情"
        title="返回"
        v-if="!$route.query.noReturn"
      ></el-page-header>
    </el-row>
    <h3 class="mb10">基本信息</h3>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row :gutter="40">
        <el-col :span="6">
          <el-form-item label="运输方式：">
            <p>{{ form.transportWayCn }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="英文名称：">
            <p>{{ form.transportWayEn }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输类型：" prop="transportType">
            {{ form.transportType | transportTypeFilter }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="报价币种：" prop="currency">
            <p>{{ form.currency }}</p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <el-form-item label="计费方式：" prop="billingMethod">
            {{ form.billingMethod | billingMethodFilter }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输周期：" prop="transportCycle">
            {{ form.transportCycle }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备注：">
            <p>{{ form.remark }}</p>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="display: flex; align-items: center">
      <h3>运费信息</h3>
    </div>
    <el-table
      :data="form.freightSettingLadderVOS"
      border
      style="width: 100%; margin-bottom: 40px"
      class="mt20"
      :span-method="ruleSpanMethod"
    >
      <el-table-column
        prop="countryCn"
        label="国家"
        align="center"
        width="300"
      ></el-table-column>
      <el-table-column
        label="计价类型"
        align="center"
        prop="pricingType"
        width="300"
      >
        <template slot-scope="scope">
          {{ scope.row.pricingType == 1 ? '单价' : '总价' }}
        </template>
      </el-table-column>
      <el-table-column label="范围" align="center" prop="billing">
        <template slot-scope="scope">
          <span>
            {{ scope.row.startBillingLadder }}~{{ scope.row.billingLadder }}
            {{ form.billingMethod == 1 ? 'm³' : 'kg' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center">
        <template slot-scope="scope">
          <span>
            {{ scope.row.price }}{{ form.currency === '美元' ? '美元' : '元' }}
          </span>
          <span v-if="scope.row.pricingType == 1">
            {{ form.billingMethod == 1 ? '/ m³' : '/ kg' }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'
  import { mapGetters } from 'vuex'
  import mixins from './util/mixins'
  export default {
    mixins: [mixins],
    data() {
      return {
        form: {
          transportWayCn: '',
          transportWayEn: '',
          transportType: null,
          transportCycle: null,
          billingMethod: null,
          currency: '人民币',
          freightSettingLadderDTOList: [],
          remark: '',
        },
        rules: {},
        tableData: [],
        triggerBol: false,
        modelVisible: false, // 添加规则弹窗
        addRuleType: '', // 添加规则类型
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    filters: {
      billingMethodFilter(status) {
        const billingMethod = {
          1: '体积',
          2: 'Max（重量，体积重：体积/6000）',
          3: 'Max（重量，体积重：体积/5000）',
          5: '重量',
        }
        return billingMethod[status]
      },
      //运输方式
      transportTypeFilter(status) {
        const transportType = {
          1: '空运',
          2: '海运',
          3: '快递',
        }
        return transportType[status]
      },
    },
    created() {
      this.getDetail(this.$route.query.id)
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      async getDetail(id) {
        this.form = await FreightSettingInteractor.getFreightDetail(id)
        this.form.freightSettingLadderVOS =
          this.form.freightSettingLadderVOS.map((item) => {
            return {
              ...item,
              tag: `${item.country}-${item.pricingType}`,
            }
          })
        this.getSpanArr(this.form.freightSettingLadderVOS)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .oper {
    padding: 20px 10px;
    height: calc(100vh - 45px);
    overflow-y: scroll;
  }
</style>
