const mixins = {
  data() {
    return {
      spanArr: [],
      pos: 0
    }
  },
  methods: {
    getSpanArr(list) {
      this.spanArr = []
      let pos = 0
      for (let i = 0; i < list.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          pos = 0
        } else {
          // if (list[i].countryCn === list[i - 1].countryCn && list[i].pricingType === list[i - 1].pricingType) {
          if (list[i].tag === list[i - 1].tag) {
            this.spanArr[pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            pos = i
          }

        }
      }
    },
    // 单元格合并
    ruleSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'countryCn' || column.property === 'pricingType' || column.property === 'oper') {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
      // else if (column.property === 'billing' || column.property === 'price') {
      //   return {
      //     rowspan: 1,
      //     colspan: 1,
      //   }
      // } else {
      //   return {
      //     rowspan: 0,
      //     colspan: 0,
      //   }
      // }
    },

  },
}

export default mixins